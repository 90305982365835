import { androidAppUrl, APP_BANNER_VIEWED_COOKIE, iosAppUrl } from '@/config/nativeApps';
import { AnalyticsCategory } from '@/constants/analytics';
import { VIRTUAL_QUEUES } from '@/utils/constants';
import { ApiRoutes, Routes } from '@/utils/routes';
import { Login } from 'legacy-features';

export default function LoginPage() {
  return (
    <Login
      routes={{ login: Routes.login, loginApi: ApiRoutes.login, register: Routes.register, help: Routes.help }}
      appUrlAndroid={androidAppUrl}
      appUrlIos={iosAppUrl}
      appBannerCookieId={APP_BANNER_VIEWED_COOKIE}
      appUrlVirtualQueues={VIRTUAL_QUEUES}
      analyticsCategory={{ error: AnalyticsCategory.error, signIn: AnalyticsCategory.signIn }}
    />
  );
}
